import React from "react";
import { Link } from "gatsby";
import ThemeToggle from "./themeToggle";
import * as s from "./footer.module.scss";

const Footer = (): JSX.Element => {
  return (
    <footer className={s.footer}>
      <ThemeToggle />
      <Link to="/">
        <small>Home :)</small>
      </Link>
    </footer>
  );
};

export default Footer;
