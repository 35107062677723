import React, { useContext } from "react";
import { Drawer } from "@mui/material";
import { ToggleContext } from "../../context/toggleProvider";
import { Link } from "gatsby";
import ContentWrapper from "../contentWrapper";
import SocialLinks from "../socialLinks";
import Button from "../button";
import Typography from "../typography";
import * as s from "./aboutDrawer.module.scss";

const AboutDrawer = (): JSX.Element => {
  const { drawerIsOpen, toggleDrawer } = useContext(ToggleContext);

  return (
    <Drawer
      PaperProps={{ className: s.aboutDrawer }}
      open={drawerIsOpen}
      onClose={toggleDrawer}
      anchor="left"
    >
      <div className={s.aboutDrawer__header}>
        <Button
          border
          uppercase
          content={{ btnText: "Close", onClick: toggleDrawer }}
        />
      </div>
      <div className={s.aboutDrawer__content}>
        <ContentWrapper>
          <Typography variant="h1" gutterBottom>
            Darren needs a job...
          </Typography>
          <Typography variant="p" gutterBottom>
            And maybe <i>you</i> can help. After some time freelancing, I've
            decided to pursue full-time gainful employment as a{" "}
            <del>professional solitaire player</del> designer. This microsite,
            is my attempt at <i>remixing the CV</i>
          </Typography>
          <Typography variant="h3" gutterBottom>
            50+ reasons why you should <i>at the very least</i> consider taking
            me on.
          </Typography>
          <Typography variant="p">
            If you're a) looking to fill or b) know of a position that:
          </Typography>
          <ul>
            {/* <li>
              Offers at least <ins>£35,000</ins> per annum.
            </li> */}
            <li>Involves working with a sports brand, music or tech.</li>
            <li>Is a middleweight role or higher.</li>
            <li>Is all of the above.</li>
          </ul>
          <Typography variant="p" gutterBottom>
            Hit me up; I'd be happy to share my portfolio and perhaps talk
            through some of my experience. In the meantime, <del>stalk</del>{" "}
            follow me on my socials and{" "}
            <Link onClick={toggleDrawer} to="/reason/01">
              check out my totally 100% officially valid reasons.
            </Link>{" "}
            or just{" "}
            <Link onClick={toggleDrawer} to="/work">
              see a few samples.
            </Link>
          </Typography>
        </ContentWrapper>
      </div>

      <div className={s.aboutDrawer__footer}>
        <SocialLinks size="lg" />
      </div>
    </Drawer>
  );
};

export default AboutDrawer;
