import React from "react";
import * as s from "./contentWrapper.module.scss";

type ContentWrapperProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  fillAvailableHeight?: boolean;
};

const ContentWrapper = ({
  children,
  style,
  fullWidth = false,
  fillAvailableHeight = false,
}: ContentWrapperProps): JSX.Element => {
  return (
    <div
      className={[s.container, fillAvailableHeight && s.maxHeight].join(" ")}
      {...(style && { style: style })}
    >
      <div
        className={[
          s.wrapper,
          fullWidth && s.wrapperFull,
          fillAvailableHeight && s.maxHeight,
        ].join(" ")}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentWrapper;
