import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import { ThemeContext } from "../context/themeProvider";
import { Helmet } from "react-helmet";
// import { Icon } from "../images/icon.png";

type SeoProps = {
  title: string;
  description: string;
};

const Seo = ({ title, description }: SeoProps): JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const { site } = useStaticQuery(graphql`
    query Seo {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `title`,
          content: title,
        },
        {
          name: `og:description`,
          content: metaDescription,
        },
        {
          name: `og:title`,
          content: title,
        },
        {
          name: `og:type`,
          content: `website`,
        },
        // {
        //   name: `og:image`,
        //   content: `${Icon}`,
        // },
      ]}
    >
      <body data-theme={theme} />
    </Helmet>
  );
};

export default Seo;
