import React from "react";
import * as s from "./typography.module.scss";

type TypographyProps = {
  variant: "h1" | "h2" | "h3" | "h4" | "p" | "small";
  children?: string | React.ReactNode;
  gutterBottom?: boolean;
  className?: string | undefined;
  align?: "center" | "inherit" | "justify" | "left" | "right";
  nowrap?: boolean;
};
const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  p: "p",
  small: "small",
};

const Typography = ({
  variant,
  children,
  align = "inherit",
  className,
  nowrap,
  gutterBottom,
}: TypographyProps) => {
  const Tag = variantMapping[variant] as keyof JSX.IntrinsicElements;

  const tagClassName = () => {
    switch (variant) {
      case "h1":
        return s.h1;
      case "h2":
        return s.h2;
      case "h3":
        return s.h3;
      case "h4":
        return s.h4;
      case "p":
        return s.p;
      case "small":
        return s.small;
    }
  };

  const alignmentClass = () => {
    switch (align) {
      case "center":
        return s.alignment__center;
      case "left":
        return s.alignment__left;
      case "right":
        return s.alignment__right;
      case "justify":
        return s.alignment__justify;
      default:
        return s.alignment__inherit;
    }
  };

  return (
    <Tag
      style={{
        whiteSpace: nowrap ? "nowrap" : "normal",
        marginBottom: gutterBottom ? "0.66em" : "0em",
      }}
      className={[
        s.typography,
        tagClassName(),
        alignmentClass(),
        className,
      ].join(" ")}
    >
      {children && children}
    </Tag>
  );
};

export default Typography;
