import React, { useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import BackgroundImage from "../backgroundImage";
import * as s from "./layout.module.scss";

type LayoutProps = {
  children: React.ReactNode;
  background?: boolean;
};

const Layout = ({ children, background }: LayoutProps): JSX.Element => {
  useEffect(() => {
    if (typeof window && typeof document !== "undefined") {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    }
  });

  return (
    <div className={s.site}>
      <div className={s.site__header}>
        <Header />
      </div>
      <main className={[s.site__content].join(" ")}>{children}</main>
      <div className={s.site__footer}>
        <Footer />
      </div>
      {background && <BackgroundImage opacity={0.4} />}
    </div>
  );
};

export default Layout;
