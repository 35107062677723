import React, { useContext } from "react";
import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from "../context/themeProvider";
import * as s from "./themeToggle.module.scss";

const ThemeToggle = (): JSX.Element => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <button
      aria-label="Toggle Theme"
      className={s.themeToggle}
      onClick={toggleTheme}
    >
      {theme === "light" ? (
        <FontAwesomeIcon icon={faMoon} />
      ) : (
        <FontAwesomeIcon icon={faSun} />
      )}
    </button>
  );
};

export default ThemeToggle;
