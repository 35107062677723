import React, { useContext } from "react";
import { Dialog } from "@mui/material";
import { ToggleContext } from "../../context/toggleProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Typography from "../../components/typography";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import * as s from "./sharingDialog.module.scss";

type SharingDialogProps = {
  url: string;
  title: string;
};

const SharingDialog = ({ url, title }: SharingDialogProps): JSX.Element => {
  const { modalIsOpen, toggleModal } = useContext(ToggleContext);

  return (
    <Dialog
      PaperProps={{ className: s.sharingDialog }}
      maxWidth={"xs"}
      open={modalIsOpen}
      onClose={toggleModal}
    >
      <div className={s.sharingDialog__header}>
        <button onClick={toggleModal}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      </div>
      <div className={s.sharingDialog__content}>
        <Typography variant="h4" gutterBottom>
          Share this with a friend or hiring manager...
        </Typography>
        <ul className={s.socials}>
          <li>
            <FontAwesomeIcon
              className={s.socials__icon}
              icon={faLinkedin}
              size="1x"
            />
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=&source=`}
            >
              <span className={s.socials__span}>
                <small>LinkedIn</small>
              </span>
            </a>
          </li>
          <li>
            <FontAwesomeIcon
              className={s.socials__icon}
              icon={faFacebook}
              size="1x"
            />
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
              <span className={s.socials__span}>
                <small>Facebook</small>
              </span>
            </a>
          </li>
          <li>
            <FontAwesomeIcon
              className={s.socials__icon}
              icon={faTwitter}
              size="1x"
            />
            <a
              href={`https://twitter.com/intent/tweet?url=${url}&text=Darren%20is%20requesting%20a%20job.%20And%20here's%20why...%20#hirethatman`}
            >
              <span className={s.socials__span}>
                <small>Twitter</small>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </Dialog>
  );
};

export default SharingDialog;
