import React from "react";
import {
  faBehance,
  faInstagram,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as s from "./socialLinks.module.scss";

type SocialLinksProps = {
  size: "1x" | "lg" | "2x";
};

const SocialLinks = ({ size = "lg" }: SocialLinksProps): JSX.Element => {
  return (
    <div className={s.socialLinks}>
      <a
        href="https://twitch.tv/darren_jpg"
        title="Darren's Twitch"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faTwitch} size={size} />
      </a>
      <a
        href="https://instagram.com/darren.eps"
        title="Darren's Instagram"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size={size} />
      </a>
      <a
        href="https://behance.net/darren1"
        title="Darren's Behance"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faBehance} size={size} />
      </a>
    </div>
  );
};

export default SocialLinks;
