import React from "react";
import * as s from "./button.module.scss";

type ButtonProps = {
  content: {
    onClick(event: React.MouseEvent<HTMLButtonElement>): void;
    btnText: string;
  };
  className?: string | undefined;
  ariaLabel?: string;
  border?: boolean;
  uppercase?: boolean;
  styles?: React.CSSProperties;
  children?: React.ReactNode;
};

const Button = ({
  content,
  border,
  children,
  uppercase,
  styles,
  className,
  ariaLabel,
}: ButtonProps): JSX.Element => {
  return (
    <button
      {...(ariaLabel && { "aria-label": ariaLabel })}
      {...(styles && { style: styles })}
      onClick={content.onClick}
      className={[className, s.btn, border ? s.btnBorder : ""].join(" ")}
    >
      {children && children}

      <span
        className={s.btn__text}
        style={{ textTransform: uppercase ? "uppercase" : "none" }}
      >
        {content.btnText}
      </span>
    </button>
  );
};

export default Button;
