import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  GatsbyImage,
  withArtDirection,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import * as s from "./backgroundImage.module.scss";

type BackgroundImageProps = {
  opacity?: number;
};
const BackgroundImage = ({ opacity }: BackgroundImageProps): JSX.Element => {
  const imageData = useStaticQuery(graphql`
    query BackgroundImageQuery {
      portrait: file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      landscape: file(relativePath: { eq: "bg-landscape.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const images = withArtDirection(
    getImage(imageData.portrait.childImageSharp) as IGatsbyImageData,
    [
      {
        media: "(orientation: landscape)",
        image: getImage(
          imageData.landscape.childImageSharp
        ) as IGatsbyImageData,
      },
    ]
  );

  return (
    <div className={s.background} style={{ opacity: opacity ? opacity : "1" }}>
      <GatsbyImage
        className={s.background__image}
        objectFit="cover"
        image={images}
        alt=""
      />
    </div>
  );
};

export default BackgroundImage;
