import React, { useContext } from "react";
import { ToggleContext } from "../context/toggleProvider";
import AboutDrawer from "./aboutDrawer";
import SharingDialog from "./sharingDialog";
import Button from "./button";
import * as s from "./header.module.scss";

const Header = () => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const pageTitle = typeof document !== "undefined" ? document.title : "";

  const { toggleDrawer, toggleModal } = useContext(ToggleContext);

  const shareUrl = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (navigator.share) {
      navigator
        .share({
          title: pageTitle,
          url: url,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        });
    } else {
      toggleModal();
    }
  };

  return (
    <header className={[s.header].join(" ")}>
      <Button
        border
        uppercase
        ariaLabel="Toggle About Page"
        content={{ btnText: "About", onClick: toggleDrawer }}
      />
      <Button
        border
        uppercase
        ariaLabel="Share this page"
        content={{ btnText: "Share", onClick: shareUrl }}
      />
      <AboutDrawer />
      <SharingDialog url={url} title={pageTitle} />
    </header>
  );
};

export default Header;
