// extracted by mini-css-extract-plugin
export var typography = "typography-module--typography--2U-Sk";
export var alignment__inherit = "typography-module--alignment__inherit--1xCpe";
export var alignment__center = "typography-module--alignment__center--3ZqSZ";
export var alignment__right = "typography-module--alignment__right--358FH";
export var alignment__justify = "typography-module--alignment__justify--wiYqx";
export var alignment__left = "typography-module--alignment__left--VJLk_";
export var h1 = "typography-module--h1--216i3";
export var h2 = "typography-module--h2--2fdGm";
export var h3 = "typography-module--h3--1TfaQ";
export var h4 = "typography-module--h4--1bypG";
export var p = "typography-module--p--2kGAW";
export var small = "typography-module--small--1iFzN";